@use '@virginmediao2/momentum-css/react/components/modal';

.backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: var(--zindex-modal-backdrop);
  animation: modalFadeIn 180ms var(--mo-animation-timing);
  border: 0;
  padding: 0;
  appearance: none;
}

.modal__wrapper {
  position: fixed;
  z-index: var(--zindex-modal-backdrop);
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  z-index: var(--zindex-modal);
  border: unset;
}

.modal__body--no-padding {
  padding: 0;
}

.modal__close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 6px;
  border: 0;
  width: 32px;
  height: 32px;
  top: 20px;
  right: 12px;
}

.modal__close--float {
  top: -16px;
  right: 0;
  z-index: var(--zindex-modal - 1);
}
