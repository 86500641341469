.media-banner {
  position: relative;
  display: grid;
  grid-template-areas: 'item';
  width: 100%;
  margin: auto;
}

.media-banner--curved {
  --_border-radius: 0 0 100% 100% / 0 0 4% 4%;
}

.media-banner--dark video {
  background-color: var(--mo-color-100);
}

@media (width >= 48em) {
  .media-banner--curved {
    --_border-radius: 0 0 100% 100% / 0 0 6% 6%;
  }
}

@media (width >= 48em) and (width < 64em) {
  .media-banner--curved {
    --_border-radius: 0 0 100% 100% / 0 0 8% 8%;
  }
}

.media-banner--curved:not(:first-child) {
  margin-top: calc(var(--padding-xl) * -1);
}

.media-banner--curved:nth-child(1) {
  z-index: calc(var(--zindex-media-banner-base) + 9);
}

.media-banner--curved:nth-child(2) {
  z-index: calc(var(--zindex-media-banner-base) + 8);
}

.media-banner--curved:nth-child(3) {
  z-index: calc(var(--zindex-media-banner-base) + 7);
}

.media-banner--curved:nth-child(4) {
  z-index: calc(var(--zindex-media-banner-base) + 6);
}

.media-banner--curved:nth-child(5) {
  z-index: calc(var(--zindex-media-banner-base) + 5);
}

.media-banner__media {
  position: relative;
  grid-area: item;
  height: 550px;
}

@media (width >= 130em) {
  .media-banner__media {
    margin: auto;
  }
}

.media-banner__media picture,
.media-banner__media video,
.media-banner__media img {
  height: 550px;
}

.media-banner__media--video div {
  height: 100%;
  width: 100%;
}

.media-banner__media video {
  display: block;
  object-fit: cover;
  object-position: center;
  max-width: 100%;
}

@media (width >= 64em) {
  .media-banner__media {
    position: relative;
    grid-area: item;
    height: 100%;
  }

  .media-banner__media picture,
  .media-banner__media video,
  .media-banner__media img {
    height: 100%;
  }

  .media-banner__media video {
    aspect-ratio: 351 / 100; /* Aspect ratio for 1440 x 410 */
  }
}

@media (width >= 90em) {
  .media-banner__media video {
    aspect-ratio: 488 / 100; /* Aspect ratio for 2000 x 410 */
  }
}

.media-banner--curved .media-banner__media {
  overflow: hidden;
  border-radius: var(--_border-radius);
  box-shadow: 0 4px 18px rgb(0 0 0 / 20%);
}

.media-banner__icon {
  position: absolute;
  bottom: -1rem;
  left: 50%;
  z-index: calc(var(--zindex-media-banner-base) + 11);
  transform: translateX(-50%);
}

@media (width >=48em) {
  .media-banner__icon {
    bottom: -1.5rem;
  }
}

.media-banner__icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background-color: var(--color-surface-bg);
  border: 0;
  border-radius: 50%;
}

.media-banner__container {
  z-index: calc(var(--zindex-media-banner-base) + 10);
  display: flex;
  grid-area: item;
  justify-content: center;
}

.media-banner__inner {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: var(--mo-container-max-width);
  padding: 3rem 1rem 10rem;
}

@media (width >= 48em) {
  .media-banner__inner {
    grid-template-columns: 1.2fr 1fr;
    padding: 5rem 3rem 10rem;
  }
}

@media (width >= 64em) {
  .media-banner {
    max-width: 2000px;
  }

  .media-banner__inner {
    grid-template-columns: 1fr 1fr;
    padding: 8rem 3rem 10rem;
  }
}

.media-banner__inner--full {
  grid-template-columns: unset;
}

.media-banner__content {
  display: flex;
  flex-direction: column;

  &--hide {
    opacity: 0;
  }

  &--fade-in {
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
