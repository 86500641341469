.media-carousel {
  --swiper-pagination-bullet-inactive-color: var(--color-tertiary-fg);
  --pagination-bullet-active-color: var(--color-primary-bg);
  --pagination-bullet-border-color: var(--color-primary-bg);

  text-rendering: auto;
  max-width: 2000px;

  &__pagination {
    &__bullet {
      --swiper-pagination-bullet-width: 16px;
      --swiper-pagination-bullet-height: 16px;
      --swiper-pagination-bullet-inactive-opacity: 1;

      z-index: 5;
      border: 1px solid var(--pagination-bullet-border-color);

      &--active {
        --swiper-pagination-bullet-inactive-color: var(--pagination-bullet-active-color);
      }
    }
  }

  &--dark {
    --swiper-theme-color: var(--color-tertiary-fg);
    --swiper-pagination-bullet-inactive-opacity: 0.7;
    --swiper-pagination-bullet-inactive-color: var(--color-primary-bg);
    --pagination-bullet-border-color: var(--color-tertiary-fg);
    --pagination-bullet-active-color: var(--color-tertiary-fg);
  }

  @media (width <= 48em) {
    &__content {
      padding: var(--padding-s);
    }
  }
}

@media (width <= 48em) {
  .media-carousel {
    --swiper-navigation-size: 22px;
    --swiper-navigation-top-offset: 95%;
  }
}
